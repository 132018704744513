/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia'
import { useMainStore } from '@/stores/main'
import { useDataReader } from '@/composables/useDataReader';
import { APAM_AUTHENTICATION_DURATION, APAM_LIST_MAX_AGE, APAM_DATA_MAX_AGE } from '@/config/config-front-end';
import { APAM_ENVIRONMENT, APAM_ENVIRONMENT_LOCAL_VALUE } from "@/config/environment-front-end";

interface Tool {
    full: string;
    abbr: string;
    codeName: string;
    route: string;
    topics: string[];
}

interface Topic {
    full: string;
    abbr: string;
    codeName: string;
    route: string;
}

interface ToolsState {
    currentPage: string,
    lastUpdated: number,
    pageProperties: PageProperties,
    tools: Record<string, Tool>;
    topics: Record<string, Topic>;
    rawData: object;
}

interface PageProperties {
    tool: string | null
    topic: string | null
    listName: string
}

export const useToolsStore = defineStore('tools', {
    state: (): ToolsState => ({
        currentPage: '',
        lastUpdated: 0,
        pageProperties: {
            tool: null,
            topic: null,
            listName: ''
        },
        tools: {
            analytics4: {
                full: 'Google Analytics 4',
                abbr: 'GA4',
                codeName: 'Analytics4',
                route: 'google-analytics4',
                topics: ['structure', 'dataprivacy', 'uncategorized']
            },
            tagmanager: {
                full: 'Google Tag Manager',
                abbr: 'GTM',
                codeName: 'TagManager',
                route: 'google-tag-manager',
                topics: ['structure', 'dataprivacy', 'uncategorized']
            },
            campaignmanager: {
                full: 'Campaign Manager',
                abbr: 'CM',
                codeName: 'CampaignManager',
                route: 'campaign-manager',
                topics: ['structure', 'uncategorized']
            }
        },
        topics: {
            structure: {
                full: 'Structure',
                abbr: 'Strct.',
                codeName: 'Structure',
                route: 'structure'
            },
            dataprivacy: {
                full: 'Data Privacy',
                abbr: 'Priv.',
                codeName: 'DataPrivacy',
                route: 'data-privacy'
            },
            uncategorized: {
                full: 'Uncategorized',
                abbr: 'Uncat.',
                codeName: 'Uncategorized',
                route: 'uncategorized'
            }
        },
        rawData: {},
    }),

    getters: {
        getTools: (state): string[] => Object.keys(state.tools),

        getToolLabel: (state) => (tool?: string | null) => {
            const currentTool = tool ?? state.pageProperties.tool
            return currentTool ? state.tools[currentTool]?.full ?? null : null
        },

        getToolAbbreviation: (state) => (tool?: string | null) => {
            const currentTool = tool ?? state.pageProperties.tool
            return currentTool ? state.tools[currentTool]?.abbr ?? null : null
        },

        getToolRoute: (state) => (tool?: string | null) => {
            const currentTool = tool ?? state.pageProperties.tool
            return currentTool ? state.tools[currentTool]?.route ?? null : null
        },

        getTopics: (state) => Object.keys(state.topics),

        getTopicLabel: (state) => (topic?: string | null) => {
            const currentTopic = topic ?? state.pageProperties.topic
            return currentTopic ? state.topics[currentTopic]?.full ?? null : null
        },

        getTopicAbbreviation: (state) => (topic?: string | null) => {
            const currentTopic = topic ?? state.pageProperties.topic
            return currentTopic ? state.topics[currentTopic]?.abbr ?? null : null
        },

        getTopicCodeName: (state) => (topic?: string | null) => {
            const currentTopic = topic ?? state.pageProperties.topic
            return currentTopic ? state.topics[currentTopic]?.codeName ?? null : null
        },

        getTopicCamelCase: (state) => (topic?: string | null) => {
            const currentTopic = topic ?? state.pageProperties.topic
            const codeName = currentTopic ? state.topics[currentTopic]?.codeName : null
            return codeName
                ? codeName[0].toLowerCase() + codeName.slice(1)
                : null
        },

        getTopicRoute: (state) => (topic?: string | null) => {
            const currentTopic = topic ?? state.pageProperties.topic
            return currentTopic ? state.topics[currentTopic]?.route ?? null : null
        },

        getToolTopics: (state) => (tool?: string | null) => {
            const currentTool = tool ?? state.pageProperties.tool
            return currentTool ? state.tools[currentTool]?.topics ?? null : null
        },

        getTopicTools: (state) => (topic?: string | null) => {
            const currentTopic = topic ?? state.pageProperties.topic
            if (!currentTopic) return null

            return Object.keys(state.tools).filter((tool) =>
                state.tools[tool].topics.includes(currentTopic)
            )
        },

        currentTool: (state) => state.pageProperties.tool,

        currentTopic: (state) => state.pageProperties.topic,

        currentListName: (state) => state.pageProperties.listName,

        getAllListNames(state): string[] {
            const listNames = [
                'tools',
                'topics',
                ...Object.keys(state.tools),
                ...Object.keys(state.topics),
            ];

            for (const tool of Object.keys(state.tools)) {
                const topics = this.getToolTopics(tool);
                if (topics) {
                    for (const topic of topics) {
                        listNames.push(`${tool}-${topic}`);
                    }
                }
            }

            return listNames;
        },

        getChildListNames(state) {
            return (listName: string): string[] => {
                const children: string[] = [];

                if (this.isThirdLevelList(listName)) {
                    children.push(
                        ...Object.keys(state.tools).flatMap(tool =>
                            state.tools[tool].topics.map(topic => `${tool}-${topic}`)
                        )
                    );
                } else if (this.isSecondLevelList(listName)) {
                    if (listName in state.tools) {
                        children.push(
                            ...state.tools[listName].topics.map(topic => `${listName}-${topic}`)
                        );
                    } else if (listName in state.topics) {
                        children.push(
                            ...Object.keys(state.tools)
                                .filter(tool => state.tools[tool].topics.includes(listName))
                                .map(tool => `${tool}-${listName}`)
                        );
                    }
                }
                return children;
            };
        },

        getListNameRoute() {
            return (listName: string): string | null => {
                if (this.getTools.includes(listName)) {
                    return this.getToolRoute(listName);
                }

                if (this.getTopics.includes(listName)) {
                    return this.getTopicRoute(listName);
                }

                const [tool, topic] = listName.split('-');
                const toolTopics = this.getToolTopics(tool);
                if (this.getTools.includes(tool) && toolTopics && toolTopics.includes(topic)) {
                    return `${this.getToolRoute(tool)}/${this.getTopicRoute(topic)}`;
                }

                return listName;
            };
        },

        isFirstLevelList() {
            return (listName: string): boolean => {
                const [tool, topic] = listName.split('-');
                return Boolean(
                    this.tools[tool] &&
                    this.topics[topic] &&
                    this.tools[tool].topics.includes(topic)
                );
            };
        },

        isSecondLevelList() {
            return (listName: string): boolean => {
                return Boolean(
                    this.tools[listName] ||
                    this.topics[listName]
                );
            };
        },

        isThirdLevelList() {
            return (listName: string): boolean => {
                return listName === 'tools' || listName === 'topics';
            };
        },

        getListLevel() {
            return (listName: string): number | null => {
                if (this.isThirdLevelList(listName)) {
                    return 3;
                } else if (this.isSecondLevelList(listName)) {
                    return 2;
                } else if (this.isFirstLevelList(listName)) {
                    return 1;
                } else {
                    return null;
                }
            };
        },

        listHasActionPlan() {
            return (listName: string): boolean => {
                return this.isFirstLevelList(listName) || this.isSecondLevelList(listName) || this.isThirdLevelList(listName);
            };
        },

        isListOutdated() {
            return (listName: string): boolean => {
                const mainStore = useMainStore();
                const data = mainStore.data;

                let updated = null;
                if (listName in data && data[listName] !== null && "updated" in data[listName]) {
                    updated = data[listName].updated;
                } else {
                    updated = data.updated;
                }

                // Based on "updated" attribute and list hierarchy:
                for (const childListName of this.getChildListNames(listName)) {
                    if (childListName in data && data[childListName] !== null && "updated" in data[childListName]
                        && data[childListName].updated > updated) {                            
                        return true;
                    }
                }

                // Based on "fetched" attribute:
                if (listName in data && data[listName] !== null && "fetched" in data[listName]) {
                    const someTimeAgo = Date.now() - APAM_LIST_MAX_AGE;
                    return data[listName].fetched < someTimeAgo;
                }

                return true;
            };
        },

        isListVeryOld() {
            return (listName: string): boolean => {
                const mainStore = useMainStore();
                const data = mainStore.data;

                if (listName in data && data[listName] !== null && "fetched" in data[listName]) {
                    const someTimeAgo = Date.now() - APAM_DATA_MAX_AGE;
                    return data[listName].fetched < someTimeAgo;
                } else {
                    return true;
                }
            };
        },

        isApamDataOutdated() {
            return (): boolean => {
                const mainStore = useMainStore();
                const data = mainStore.data;

                if ("fetched" in data) {
                    if (APAM_ENVIRONMENT === APAM_ENVIRONMENT_LOCAL_VALUE) {
                        const someTimeAgo = Date.now() - (10 * 1000);
                        return data.fetched < someTimeAgo;
                    } else {
                        const someTimeAgo = Date.now() - APAM_LIST_MAX_AGE;
                        return data.fetched < someTimeAgo;
                    }
                } else {
                    return true;
                }
            };
        },

        updated(): number {
            const mainStore = useMainStore()
            const currentListName = this.pageProperties.listName
            const data = mainStore.data

            if (currentListName && data[currentListName]?.updated) {
                return data[currentListName].updated
            }
            return data.updated || 0
        },

        fetched() {
            return (listName: string): number | null => {
                const mainStore = useMainStore();
                const data = mainStore.data;

                if (listName in data && "fetched" in data[listName]) {
                    return data[listName].fetched;
                } else {
                    return null;
                }
            };
        },

        gotNewData() {
            return (listName: string): boolean => {
                const mainStore = useMainStore();
                const flags = mainStore.flags;

                if ("apam" in flags.gotNewData && flags.gotNewData.apam) {
                    return true;
                } else if (typeof listName === 'string' && listName in flags.gotNewData) {
                    return flags.gotNewData[listName];
                } else {
                    return false;
                }
            };
        },

        gotNewApamData() {
            return (): boolean => {
                const mainStore = useMainStore();
                const flags = mainStore.flags;

                if ("apam" in flags.gotNewData && flags.gotNewData.apam) {
                    return true;
                } else {
                    return false;
                }
            };
        },

        scoreUpdated() {
            return (listName: string): boolean => {
                const mainStore = useMainStore();
                const flags = mainStore.flags;

                if (listName in flags.scoreUpdated) {
                    return flags.scoreUpdated[listName];
                } else {
                    return true;
                }
            };
        },

        isAuthenticationTimeValid() {
            return (): boolean => {
                const mainStore = useMainStore();

                return (Date.now() - mainStore.app.authenticationTime) <= APAM_AUTHENTICATION_DURATION;
            };
        },

    },
    actions: {
        updateListName(listName: string) {
            this.pageProperties.listName = listName
        },

        setPage(page: string) {
            this.currentPage = page
            this.updatePageProperties(page)
        },

        updatePageProperties(page: string = '') {
            const pageParts = page.split('-')
            const properties: PageProperties = {
                tool: null,
                topic: null,
                listName: ''
            }

            // Parse tool            
            const tool = Object.keys(this.tools).find(t => pageParts.includes(t))
            if (tool) properties.tool = tool

            // Parse topic
            const topic = Object.keys(this.topics).find(t => page.includes(t))
            if (topic) properties.topic = topic

            // Set listName
            if (pageParts[0] === "list") {
                properties.listName = page.replace("list-", "")
            } else if (pageParts[0] === "actionplan") {
                properties.listName = page.replace("actionplan-", "")
            } else if (properties.tool && properties.topic) {
                properties.listName = `${properties.tool}-${properties.topic}`
            } else if (properties.tool) {
                properties.listName = properties.tool
            } else if (properties.topic) {
                properties.listName = properties.topic
            }

            this.pageProperties = properties
        },

        toggleItemInCart(url: string) {
            const mainStore = useMainStore()
            const cart = mainStore.cart

            if (cart.includes(url)) {
                mainStore.removeFromCart([url])
            } else {
                mainStore.addToCart([url])
            }
        },

        *busyItemList(listName: string = '', nameSelection?: string | string[]): Generator {
            const mainStore = useMainStore();

            if (listName === '') {
                listName = this.currentListName;
            }

            if (listName in mainStore.actions) {
                let names = [];
                const actions = mainStore.actions[listName];
                if (Array.isArray(nameSelection)) {
                    names = nameSelection;
                } else if (nameSelection !== undefined) {
                    names = [nameSelection];
                } else {
                    names = Object.keys(mainStore.actions[listName]);
                }

                let urls: string | any[] = [];
                for (const name in actions) {
                    if (names.includes(name)) {
                        urls = [...urls, ...actions[name]];
                    }
                }

                const dataReader = useDataReader(listName);
                for (let i = 0, j = urls.length; i < j; i++) {
                    yield dataReader.getItemInfoByUrl(urls[i]);
                }
            }
        }
    }
})