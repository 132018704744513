<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { useMainStore } from '@/stores/main'
import { useAuth } from '@/composables/useAuth'
import SignInView from '@/components/views/SignInView.vue'
import { APAM_ADMIN_WHITE_LIST, APAM_VERSION } from '@/config/config-front-end'
import { APAM_ENVIRONMENT } from '@/config/environment-front-end'
import { useToast } from 'primevue/usetoast'
import Toast from 'primevue/toast'

const mainStore = useMainStore()
const route = useRoute()
const toast = useToast()

const isAdmin = ref(false)
const { isAuthenticated } = useAuth()

const isActiveRoute = (path: string) => route.path === path

const isActiveRouteTools = () => {
  return isActiveRoute('/tools') || route.path.startsWith('/google-analytics4') || route.path.startsWith('/google-tag-manager') || route.path.startsWith('/campaign-manager')
}

const isActiveRouteTopics = () => {
  return isActiveRoute('/topics') || isActiveRoute('/structure') || isActiveRoute('/data-privacy') || isActiveRoute('/uncategorized')
}

const closeModal = () => {
  mainStore.app.modal.opened = false
  mainStore.closeModal()
}

const closeModalAndReloadDocument = async () => {
  closeModal()
  await caches.keys().then(keyList =>
    Promise.all(keyList.map(key => caches.delete(key)))
  )
  window.location.reload()
}

const handleGoogleClientEvents = () => {
  // Handle loader-related events
  const loaderEvents = [
    'GoogleClientAuth2Loaded',
    'GoogleClientExceptionFired',
    'GoogleClientInitializedFailed'
  ]

  loaderEvents.forEach(eventName => {
    document.addEventListener(eventName, () => {
      mainStore.setLoaderVisible(false)
    })
  })
}

const checkAdmin = async () => {
  if (mainStore.app.authenticationEmail != undefined && APAM_ADMIN_WHITE_LIST.length > 0) {
    if (APAM_ADMIN_WHITE_LIST.includes(mainStore.app.authenticationEmail) && APAM_ENVIRONMENT !== 'prod') {
      isAdmin.value = true
    }
  }
}

const updateOnlineStatus = () => {
  mainStore.updateOffline(!navigator.onLine)
}

// Lifecycle hooks
onMounted(() => {
  mainStore.setToastInstance(toast)
  mainStore.setLoaderVisible(false)
  window.addEventListener('online', updateOnlineStatus)
  window.addEventListener('offline', updateOnlineStatus)
  updateOnlineStatus()
  handleGoogleClientEvents()
  checkAdmin()
})

onUnmounted(() => {
  window.removeEventListener('online', updateOnlineStatus)
  window.removeEventListener('offline', updateOnlineStatus)

  const loaderEvents = [
    'GoogleClientAuth2Loaded',
    'GoogleClientExceptionFired',
    'GoogleClientInitializedFailed'
  ]

  loaderEvents.forEach(eventName => {
    document.removeEventListener(eventName, () => {
      mainStore.setLoaderVisible(false)
    })
  })
})
</script>

<template>
  <div class="appContainer">
    <header class="surface-card pt-4 pb-0 header-section">
      <div class="flex justify-content-center align-items-center mb-4">
        <h1 class="text-4xl text-900 m-0">APAM</h1>
      </div>

      <div class="flex justify-content-center align-items-center">
        <div class="nav-menu">
          <Button label="Tools" text :class="{ 'active-nav': isActiveRouteTools() }" @click="$router.push('/tools')" />
          <Button label="Topics" text :class="{ 'active-nav': isActiveRouteTopics() }"
            @click="$router.push('/topics')" />
          <Button label="About" text :class="{ 'active-nav': route.path.startsWith('/about') }"
            @click="$router.push('/about/release-notes')" />
          <Button v-if="isAdmin" label="Admin" text :class="{ 'active-nav': isActiveRoute('/admin') }"
            @click=" $router.push('/admin')" />
        </div>
      </div>
    </header>


    <main role="main" class="main-content">
      <template v-if="isAuthenticated">
        <RouterView />
      </template>
      <template v-else>
        <SignInView />
      </template>
    </main>

    <footer>
      <p class="m-3">v{{ APAM_VERSION }} | Made with ♥ by the CDMO Team.</p>
    </footer>

    <Toast position="bottom-center" group="br" />

    <!-- Dialog -->
    <Dialog v-model:visible="mainStore.app.modal.opened" :style="{ width: '600px' }">
      <template #header>
        <h3>{{ mainStore.app.modal.title }}</h3>
      </template>
      <p>{{ mainStore.app.modal.message }}</p>
      <img v-if="mainStore.app.modal.image" class="guide" :src="mainStore.app.modal.image" alt="Guide">
      <template #footer>
        <Button v-if="mainStore.app.modal.title === 'Update'" label="Reload" outlined
          @click="closeModalAndReloadDocument" />
        <Button :label="mainStore.app.modal.buttonLabel" outlined @click="closeModal" />
      </template>
    </Dialog>

    <ScrollTop />
  </div>
</template>

<style scoped>
.main-content {
  min-height: 100vh;
}

footer {
  padding: 24px;
  background: var(--app-secondary-color);
  color: var(--app-light-text-color);
  text-align: center;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.guide {
  width: auto;
  height: 50%;
  margin-top: auto;
}

.nav-menu {
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.header-section {
  border-bottom: 1px solid #eee;
}

:deep(.p-button.p-button-text) {
  color: var(--primary-color);
  font-weight: 500;
}

:deep(.p-button.p-button-text:hover) {
  background: var(--primary-50);
  color: var(--app-primary-color) !important;
  border-bottom: 4px solid var(--app-primary-color);
}

:deep(.active-nav) {
  color: var(--app-primary-color) !important;
  border-bottom: 4px solid var(--app-primary-color);
}

:deep(.p-scrolltop) {
  background: var(--app-secondary-color);
  color: var(--apam-light-gray-color);
  border-color: var(--apam-light-gray-color);
}

:deep(.p-scrolltop.p-button:not(:disabled):hover) {
  background: var(--apam-light-gray-color);
  color: var(--app-secondary-color);
  border-color: var(--app-secondary-color);
}
</style>
